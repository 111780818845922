import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col, useScreenClass, Hidden, Visible } from 'react-grid-system'

const WebsiteProcess = (props) => {
  const data = useStaticQuery(graphql`{
  file(relativePath: {eq: "website-process.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 95, layout: FULL_WIDTH)
    }
  }
}
`)
  const screenClass = useScreenClass()
  return (
    <section>
      <Container style={{
        paddingLeft: ['md', 'lg', 'xl'].includes(screenClass) ? '3rem' : '1.5625rem',
        paddingRight: ['md', 'lg', 'xl'].includes(screenClass) ? '3rem' : '1.5625rem',
      }}>
        <Row>
          <Col lg={10} offset={{ lg: 1 }}>
            <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default WebsiteProcess
