import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  Container,
  Row,
  Col,
  useScreenClass,
  Hidden,
  Visible,
} from "react-grid-system"
import styled from "styled-components"

const Section = styled.section`
  background-color: ${(props) => props.theme.colors.darker};
  color: ${(props) => props.theme.colors.white};
  margin-bottom: -1px;
  padding-top: 5.25rem;
`

const List = styled.ul`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1600px) {
    flex-direction: column;
  }
`
const ListItem = styled.li`
  align-items: center;
  display: flex;
  flex: 0;
  flex-direction: column;
  justify-content: between;
  position: relative;
  tex-align: center;
  &::before {
    background-color: ${(props) => props.theme.colors.highlight};
    content: "";
    display: block;
    height: 1px;
    width: 18px;
    position: absolute;
    top: 106px;
    left: -9px;
  }
  &:first-child {
    &::before {
      display: none;
    }
  }
  @media only screen and (max-width: 1600px) {
    margin-bottom: 36px;
    &::before {
      height: 18px;
      width: 1px;
      top: calc(100%);
      left: 50%;
    }
    &:first-child {
      &::before {
        display: block;
      }
    }
    &:last-child {
      &::before {
        display: none;
      }
    }
  }
`
const Ring = styled.div`
  border: 1px solid ${(props) => props.theme.colors.dark};
  border-radius: 50%;
  box-sizing: border-box;
  padding: 14px;
  height: 212px;
  margin: 0 27px 4.35rem;
  position: relative;
  width: 212px;
  &::after {
    background-color: ${(props) => props.theme.colors.dark};
    content: "";
    display: block;
    height: 64px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 100%;
    transition: all ${(props) => props.theme.styles.transition};
    width: 1px;
    ${ListItem}:hover & {
      opacity: 1;
    }
  }
  @media only screen and (max-width: 1600px) {
    margin-bottom: 1.15rem;
    &::after {
      display: none;
    }
  }
`
const Circle = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.dark};
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  transition: all ${(props) => props.theme.styles.transition};
  width: 100%;
  ${ListItem}:hover & {
    background-color: ${(props) => props.theme.colors.highlight};
    color: ${(props) => props.theme.colors.white};
  }
`
const Title = styled.p`
  color: ${(props) => props.theme.colors.highlight};
  font-family: ${(props) => props.theme.fonts.serif};
  font-size: 1.95rem;
  line-height: 1.05;
  transition: color ${(props) => props.theme.styles.transition};
  ${ListItem}:active &,
  ${ListItem}:focus &,
  ${ListItem}:hover & {
    color: ${(props) => props.theme.colors.white};
  }
`
const SubText = styled.div`
  opacity: 0;
  text-align: center;
  transition: all ${(props) => props.theme.styles.transition};
  ${ListItem}:hover & {
    opacity: 1;
  }
  @media only screen and (max-width: 1600px) {
    height: 0;
    ${ListItem}:hover & {
      height: 2.25rem;
    }
  }
`

const StyledImage = styled(GatsbyImage)`
  margin-top: 6.5rem;
`

const FiveD = (props) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "BANG.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95, layout: FULL_WIDTH)
        }
      }
    }
  `)
  const screenClass = useScreenClass()
  return (
    <Section className={props.className}>
      <Container
        style={{
          paddingLeft: ["md", "lg", "xl"].includes(screenClass)
            ? "3rem"
            : "1.5625rem",
          paddingRight: ["md", "lg", "xl"].includes(screenClass)
            ? "3rem"
            : "1.5625rem",
        }}
      >
        <Row>
          <Col
            lg={8}
            offset={{ lg: 2 }}
            style={{
              marginBottom: ["md", "lg", "xl"].includes(screenClass)
                ? "4rem"
                : "1rem",
            }}
          >
            <h2 className={`headline tagline`}>
              What Does A Branding Process Look Like?
            </h2>
            <p>
              Our 5D branding process starts with assessing your business and
              market landscape to define who you are, what is working, what is
              not, and where you want to go. Using our 5D branding method and
              brand development process, we breathe life into your company by
              giving it dimension and depth.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row justify="center">
          <Col lg={`content`}>
            <List>
              <ListItem>
                <Ring>
                  <Circle>
                    <Title>Discover</Title>
                  </Circle>
                </Ring>
                <SubText>UNCOVERING INSIGHTS</SubText>
              </ListItem>
              <ListItem>
                <Ring>
                  <Circle>
                    <Title>Define</Title>
                  </Circle>
                </Ring>
                <SubText>STRATEGY & MESSAGING</SubText>
              </ListItem>
              <ListItem>
                <Ring>
                  <Circle>
                    <Title>Develop</Title>
                  </Circle>
                </Ring>
                <SubText>CREATIVE & ASSETS</SubText>
              </ListItem>
              <ListItem>
                <Ring>
                  <Circle>
                    <Title>Diffuse</Title>
                  </Circle>
                </Ring>
                <SubText>HOW WE ENGAGE</SubText>
              </ListItem>
              <ListItem>
                <Ring>
                  <Circle>
                    <Title>Dissect</Title>
                  </Circle>
                </Ring>
                <SubText>MEASURING & REPORTING</SubText>
              </ListItem>
            </List>
          </Col>
        </Row>
      </Container>
      <Container
        style={{
          maxWidth: "100%",
          paddingLeft: ["md", "lg", "xl"].includes(screenClass)
            ? "3rem"
            : "1.5625rem",
          paddingRight: ["md", "lg", "xl"].includes(screenClass)
            ? "3rem"
            : "1.5625rem",
        }}
      >
        <StyledImage image={data.file.childImageSharp.gatsbyImageData} />
      </Container>
    </Section>
  )
}

export default FiveD
